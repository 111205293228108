.form-container {
  width: 600px;
  margin: -65px auto 0;
  padding: 30px 50px;
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: "Helvetica Neue MediumCond";

  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 36px;
    color: #b38bff;
  }
  .form-control-input {
    background: transparent;
    border: none;
    color: #fff;
    input {
      margin: 0;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }

  label {
    font-size: 18px;
  }

  input {
    padding: 12px;
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #555;
  }

  button {
    padding: 10px;
    background-color: #b38bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.2s ease-in-out;
    width: 100%;
    display: block;
  }

  button:hover {
    background-color: #8c5fb2;
  }
}
