.candidate-data {
  .candidate-personal-info {
    .candidate-name {
      font-size: 1.5rem;
    }

    .candidate-age {
      font-size: 1.2rem;
    }

    .candidate-gender {
      color: #7c7c7c;
    }

    .candidate-location {
      color: #464646;
      font-size: 0.9rem;
    }
  }
  .disability {
    .icon-disability {
      color: #cc0000;
      font-size: 1.2rem;
    }

    p {
      color: #cc0000;
      font-size: 0.9rem;
    }
  }
}
