.tags-container {
  // padding: 2.5rem 3rem;
  .tags-tabs-container {
    .active {
      font-family: "Helvetica Neue MediumCond";
    }
  }
  .tags-action-btns {
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
    opacity: 0;

    &.show {
      pointer-events: all;
      opacity: 1;
    }
  }

  #tagsAccordion {
    .tags-tabs-content-container {
      .qualification-tab-content,
      .personal-tab-content {
        display: none;
      }
      .active {
        display: block;
      }
    }
    .accordion-item {
      border: none;
      overflow: hidden;
      margin-bottom: 10px;
      &.checked {
        button {
          span {
            position: relative;
            padding-right: 20px;
            font-family: "Helvetica Neue MediumCond";
            &:after {
              content: "";
              width: 5px;
              height: 5px;
              border-radius: 100px;
              background-color: #cc0000;
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .accordion-button {
        font-weight: 600;
        font-size: 0.9rem;

        &:focus {
          border: none;
          box-shadow: none;
        }

        &:not(.collapsed) {
          color: #000;
          box-shadow: none;
          background-color: transparent;
          font-family: "Helvetica Neue MediumCond";
        }

        &:after {
          width: 1rem;
          height: 1rem;
          background-size: 1rem;
        }
      }

      .accordion-collapse {
        .accordion-body {
          padding: 0.5rem 0 0;

          .wraped-words {
            text-align: start;
            justify-content: start;

            span {
              flex-grow: 0;
              width: 33.33%;
              margin: 6px 0;
              word-break: break-word;
              // text-align: center;

              input {
                display: none;

                &:checked + label {
                  font-family: "Helvetica Neue MediumCond";
                }
              }

              label {
                cursor: pointer;
                font-size: 0.8rem;
                font-weight: 600;
              }
            }
          }

          .personal-container {
            .personal-title {
              width: 30%;

              b {
                font-size: 0.8rem;
              }

              &.availability {
                b {
                  display: none;

                  &.active {
                    display: block;
                  }
                }
              }
            }

            .data-side,
            .one-range {
              width: 70%;
            }

            .data-side {
              > span {
                font-size: 15px;
                margin-right: 1.5rem;
              }

              &.languages {
                > span {
                  margin-bottom: 1rem;
                  width: 30%;
                  display: inline-block;
                  margin-right: 5px;
                }
              }
            }

            span {
              input[type="radio"] {
                display: none;

                &:checked + label {
                  font-family: "Helvetica Neue MediumCond";
                }
              }

              label {
                cursor: pointer;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
