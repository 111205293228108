nav {
  background-color: #fff;

  .navbar-brand {
    width: 75px;
  }

  .navbar-collapse {
    .navbar-nav {
      a {
        color: #7c7c7c;
        font-size: rem(14px);
        // font-size: 14px;
        font-family: "Helvetica Neue ThinCond";
        font-weight: 600;
        letter-spacing: 1px;

        &.active {
          pointer-events: none;
          font-family: "Helvetica Neue MediumCond";
        }
      }

      .concierge-link {
        color: #e8ad0d;
      }
    }

    .user-controls {
      position: relative;
      top: -45px;

      i {
        color: #7c7c7c;
        font-size: rem(19px);
        margin-left: 25px;
        cursor: pointer;
      }
    }
  }
}
