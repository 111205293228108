.custom-modal {
  &.submitList {
    width: 450px;
    background: #fff;

    .create-list {
      p {
        font-size: 16px;
      }
    }

    .list-container {
      .title {
        color: #000;
        // font-size: 1.1rem;
      }

      .date {
        color: #7c7c7c;
        // font-size: 1.1rem;
      }
    }
    ul {
      list-style: none;
      padding-left: 20px;
    }
    .bold-text {
      font-weight: 700;
    }
    .sm-bold-text {
      font-weight: 800;
    }
    .submit-btn {
      font-family: "Helvetica Neue MediumCond";
      letter-spacing: 1px;
      font-weight: 700;
    }
  }

  &.submitList {
    .close-modal {
      display: block;
      margin-left: auto;

      i {
        color: #7c7c7c;
      }
    }
  }
}
