.nav_user_modal {
  position: fixed;
  // position: absolute;
  right: -450px;
  top: 74px;
  background: #fff;
  height: calc(100vh - 74px);
  transition: all 0.4s ease-in-out;
  padding: 1.5rem;
  z-index: 999;
  width: 450px !important;
  overflow: auto;
  // top: $navH+px;

  .modal-title {
    font-weight: 700;
    font-size: 1.25rem;
  }

  // active modal
  &.active {
    right: 0;
  }

  // Notification Modal
  &.notification-modal {
    padding: 1.5rem 0;

    .modal-title {
      padding: 0 1.5rem;
    }

    .notifications-control {
      padding: 0 1.5rem;
      opacity: 0;
      pointer-events: none;
      transition: all 0.4s ease-in-out;
      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }

    .notifications-container {
      .notification-day {
        font-size: 1rem;
        color: #7c7c7c;
        padding: 0 1.5rem;
        margin-bottom: 8px;
      }

      .notification-item {
        position: relative;
        padding: 0.5rem 1.5rem;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
          background-color: #e4e4e4;

          .notification-box {
            input[type="checkbox"] {
              opacity: 1;
            }
          }
        }

        .notification-box {
          input[type="checkbox"] {
            opacity: 0;
            margin-left: -1.1rem;
            margin-right: 5px;
            transition: all 0.4s ease-in-out;

            &:checked {
              opacity: 1;
            }
          }

          .notification-title {
            font-size: 1rem;
            color: #000;
            cursor: pointer;
          }
        }

        .notification-time {
          font-size: 1rem;
          color: #7c7c7c;
        }
      }
    }
  }

  // User Profile Modal
  &.user-profile-modal {
    .profile-info {
      .user-name {
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .user-data {
        color: #464646;
        font-size: 1rem;
        margin-bottom: 10px;
      }
    }

    .react-tel-input .form-control {
      width: 100%;
      border-color: #dee2e6;

      &:focus {
        box-shadow: none;
        border-color: #000;

        & + .flag-dropdown {
          border: 1px solid #000;
          border-right-color: #ccc;
        }
      }
    }
  }

  &.contact-modal {
    .contact-modal-container {
      position: relative;
      padding-bottom: 175px;
    }

    .social-container {
      position: absolute;
      bottom: 45px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;

      a {
        display: inline-block;
        margin: 6px 10px;
        flex-grow: 1;
        font-size: 20px;
        color: #7c7c7c;
        text-decoration: none;
      }
    }
  }
}
