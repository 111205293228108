.custom-modal {
  &.addToList {
    width: 450px;
    background: #fff;
    &.col-4 {
      width: 33.33333%;
    }
    &.over-candidateModal {
      z-index: 999999;
    }
    .create-list {
      p {
        font-size: 16px;
      }
    }
    .lists-overflow {
      height: calc(100vh - 55px);
      overflow: scroll;
      padding-bottom: 60px;
    }
    .list-container {
      .title {
        color: #000;
        // font-size: 1.1rem;
      }

      .date {
        color: #7c7c7c;
        // font-size: 1.1rem;
      }
    }
  }

  &.addToList {
    .close-modal {
      display: block;
      margin-left: auto;

      i {
        color: #7c7c7c;
      }
    }
  }
}
