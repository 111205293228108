.main-two-sides-container {
  position: relative;
  overflow: hidden;
  padding: 26px 15px;

  .candidates-side {
    padding-right: 455px;
    // padding-right: 400px;
    transition: all 0.4s ease-in-out;
    min-height: calc(100vh - 222px);
    position: relative;
    &.moveToRight {
      padding-right: 0;
    }
  }

  .side-bar {
    width: 450px;
    // padding: 1.5rem;
    background: #fff;
    transition: all 0.4s ease-in-out;
    transform: translateX(90%);
    height: calc(100vh - 125px);
    position: fixed;
    right: 0;
    opacity: 0;
    overflow: auto;
    top: 168px;

    &.animated {
      opacity: 1;
      transform: translateX(0);
      position: fixed;
      top: 124px;
      right: 0;
    }

    > div {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.candidate-container {
  position: relative;
  // overflow: hidden;
  cursor: pointer;
  margin-bottom: 0.5rem;
  transition: all 0.4s ease-in-out;

  &:hover {
    transform: scale(1.1);
    position: relative;
    z-index: 11;

    .candidate-control,
    .user-info {
      opacity: 1;
    }

    .user-info {
      bottom: 0;
      padding: 10px 8px;
      background: #00000040;
    }

    .candidate-control {
      &.show-controls {
        .clearFromList {
          opacity: 1;
        }
      }
    }
  }

  &.selected {
    transform: none;
    &:hover {
      img {
        transform: scale(1);
      }
    }

    .overlay {
      opacity: 1;
    }

    // .candidate-control,
    .user-info {
      opacity: 1;
    }

    .user-info {
      bottom: 0;
      padding: 10px 8px;
      background: #00000040;
    }
    i {
      &.addToList-btn {
        display: none !important;
      }
      &.removeToList-btn {
        font-size: 1.5rem;
        display: block !important;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000007d;
    z-index: 10;
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  img {
    width: 100%;
    transition: opacity 0.5s ease-in-out;
  }

  .candidate-control {
    position: absolute;
    top: 30px;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    padding: 0 8px;
    opacity: 0;
    z-index: 10;
    transition: all 0.4s ease-in-out;

    &.show-controls {
      opacity: 1;

      .clearFromList {
        opacity: 0;
        transition: all 0.4s ease-in-out;
      }
    }

    i {
      // font-size: 25px;
      color: #fff;
      &.addToList-btn {
        display: block;
      }
      &.removeToList-btn {
        display: none;
      }
    }

    .clearFromList {
      color: #cc0000;
      font-size: 1.5rem;
    }
  }

  .user-info {
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    padding: 0 8px;
    opacity: 0;
    z-index: 10;
    transition: all 0.4s ease-in-out;

    span {
      color: #fff;
    }
  }
}

.row > .reset-col {
  padding-right: calc(0.5rem * 0.5);
  padding-left: calc(0.5rem * 0.5);
  transition: all 0.4s ease-in-out;
}

.sidebar-container {
  padding: 2.5rem 3rem;
}
