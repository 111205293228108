.candidate-image-wrapper {
  .selectedItemInModal {
    display: none;
  }
  &.selected {
    .selectedItemInModal {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #26af5169 !important;
      font-size: 3rem !important;
      display: block;
    }
  }
}
