$navH: 120;
$mainBarH: 50;

@function multiply($x, $multiplier) {
  @return $x + $multiplier + px;
}

body {
  background-color: #e4e4e4;
  padding-top: multiply($navH, $mainBarH);
  overflow-x: hidden;
  // font-family: 'Helvetica Neue ThinCond';
  // font-weight: 600;
  // letter-spacing: 1px;
  // font-size: rem(14px);
}

nav {
  height: $navH + px;
}

.control-bar-container {
  top: $navH + px;
}

.main-two-sides-container .side-bar.animated {
  top: multiply($navH - 2, $mainBarH); // -2px
}

.nav_user_modal {
  top: $navH + px;
  height: calc(100vh - 120px);
}

// .custom-modal.addToList {
//     padding-top: $navH + 24+ px !important;
// }
.main-two-sides-container .side-bar {
  height: calc(100vh - 168px);
}

.red-btn {
  color: #cc0000;
}

.blue-btn {
  color: #327cf6;
}

.reset-btn {
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 0.9rem;
  transition: all 0.4s ease-in-out;
}

.bordered-input {
  border: 1px solid #dee2e6 !important;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.375rem;
}

.submit-btn {
  font-weight: 600;
  font-size: 0.9rem;
  padding: 7px 30px;
  border: 1px solid #e4e4e4;

  &:hover {
    background-color: #e4e4e4;
  }
}
.dark-btn {
  background: #000;
  color: #fff;
  &:hover {
    background-color: #000;
  }
}

textarea {
  resize: none;
}

.form-control {
  &:focus {
    box-shadow: none;
    border-color: #000;
  }
}

.required-star,
.red-text {
  color: #cc0000;
}

img {
  max-width: 100%;
}

p,
.normal-text {
  font-family: "Helvetica Neue ThinCond";
  font-weight: 600;
  letter-spacing: 1px;
  font-size: rem(14px);

  > b {
    font-family: "Helvetica Neue MediumCond";
  }
}

.bold-text {
  font-family: "Helvetica Neue MediumCond";
  letter-spacing: 1px;
  font-size: rem(30px);
}
.react-tel-input {
  font-size: rem(15px);
}

.mid-bold-text {
  font-family: "Helvetica Neue MediumCond";
  letter-spacing: 1px;
  font-size: rem(24px);
}

.sm-bold-text {
  font-family: "Helvetica Neue MediumCond";
  letter-spacing: 1px;
  font-size: rem(16px);
}

.thin-text {
  font-family: "Helvetica Neue ThinCond";
  font-size: 20px;
}
.mid-text {
  font-family: "Helvetica Neue MediumCond";
}

.font-20 {
  font-size: 20px;
}

.icon,
.pointer {
  cursor: pointer;
}

.wraped-words {
  display: flex;
  flex-wrap: wrap;
  // text-align: center;
  // justify-content: space-between;
  text-align: start;
  justify-content: start;
  span {
    display: inline-block;
    margin: 6px;
    flex-grow: 1;
    font-size: 0.85rem;

    flex-grow: 0;
    width: 25%;
    margin: 6px 0;
    word-break: break-word;
  }
}

.vh-100 {
  height: 100vh !important;
}

.favorites-icons {
  position: relative;
  width: 30px;
  height: 30px;
  i {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    font-size: 1.5rem;
    color: #fff;

    &.active {
      display: block;
    }
  }

  &.maybeIdeal-icons {
    i {
      right: auto;
      left: 0;
    }
  }
}
.disabled {
  pointer-events: none;
}
.visible {
  opacity: 1;
  pointer-events: all;
}
.hidden {
  opacity: 0;
  pointer-events: none;
}
a {
  text-decoration: none;
  color: inherit;
}
.whats-green {
  color: #25d366;
}
.maybe-list {
  .favorites-icons {
    &.maybe {
      i {
        &.icon-clock {
          display: inline-block;
        }
        &.icon-checkmark {
          display: none;
        }
      }
    }
  }
}

.ideal-list {
  .favorites-icons {
    &.ideal {
      i {
        &.icon-clock {
          display: none;
        }
        &.icon-checkmark {
          display: inline-block;
        }
      }
    }
  }
}

.candidate-status {
  .candidate-availability {
    font-size: 0.9rem;
    padding-right: 20px;
    position: relative;

    &::after {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      top: 50%;
      right: -1px;
      border-radius: 100px;
      transform: translateY(-50%);
    }

    &.available {
      &::after {
        background-color: #5cc889;
      }
    }

    &.available-on {
      &::after {
        background-color: #e8ad0d;
      }
    }

    &.not-available {
      &::after {
        background-color: #cc0000;
      }
    }
  }
}

.hide {
  display: none;
}
.show {
  display: block;
}
.font-600 {
  font-weight: 600;
}
.md-font {
  font-family: "Helvetica Neue MediumCond";
}
.no-border {
  border: none !important;
}
input {
  &::placeholder {
    color: #000;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #000;
  }
}
.input-reset {
  background: none;
  border: none;
  outline: none;
  color: #7c7c7c;
}

.addToList-btn {
  font-size: 1.5rem;
  color: #fff;
}

.actions-btns {
  position: absolute;
  right: 40px;
  top: 40px;
}

// range input
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 60px;
  height: 3px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 16px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  width: 60px;
  height: 3px;
  background: #fff;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  width: 60px;
  height: 3px;
  background: #fff;
  border-radius: 16px;
}

input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: #434343;
  margin-top: -4px;
}

input[type="range"]::-moz-slider-thumb {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: #7c7c7c;
  margin-top: -4px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: #353b3a #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #353b3a;
  border-radius: 5px;
  border: 1px solid #ffffff;
}

// @media (max-width:1990px) {
html {
  font-size: 13px;
}

// }

@media (min-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 16px;
  }
}
