// -----------------------------------------------------------------------------
// This file contains all application-wide Sass functions.
// -----------------------------------------------------------------------------

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    01. Convert from px to rem
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@function rem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}