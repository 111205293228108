@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon/icomoon.eot?d2z0bb");
  src: url("../../fonts/icomoon/icomoon.eot?d2z0bb#iefix")
      format("embedded-opentype"),
    url("../../fonts/icomoon/icomoon.ttf?d2z0bb") format("truetype"),
    url("../../fonts/icomoon/icomoon.woff?d2z0bb") format("woff"),
    url("../../fonts/icomoon/icomoon.svg?d2z0bb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-minus-circle:before {
  content: "\e928";
}
.icon-arrow-down-solid:before {
  content: "\e926";
}
.icon-arrow-up-solid:before {
  content: "\e927";
}
.icon-calendar-days-regular:before {
  content: "\e924";
}
.icon-calendar-regular:before {
  content: "\e925";
}

.icon-circle-checkmark:before {
  content: "\e923";
}

.icon-x:before {
  content: "\e922";
}

.icon-pen-square-regular:before {
  content: "\e91e";
}

.icon-pen-square-solid:before {
  content: "\e91f";
}

.icon-play-solid:before {
  content: "\e920";
}

.icon-circle-play-regular:before {
  content: "\e921";
}

.icon-filter-solid:before {
  content: "\e919";
}

.icon-eye-slash-regular:before {
  content: "\e91a";
}

.icon-eye-slash-solid:before {
  content: "\e91b";
}

.icon-eye-regular:before {
  content: "\e91c";
}

.icon-eye-solid:before {
  content: "\e91d";
}

.icon-plus:before {
  content: "\e918";
}

.icon-sort:before {
  content: "\e913";
}

.icon-plus-circle:before {
  content: "\e90e";
}

.icon-heart-blank:before {
  content: "\e907";
}

.icon-checkmark:before {
  content: "\e900";
}

.icon-clear-btn:before {
  content: "\e901";
}

.icon-clock:before {
  content: "\e902";
}

.icon-close:before {
  content: "\e903";
}

.icon-disability:before {
  content: "\e904";
}

.icon-facebook:before {
  content: "\e905";
}

.icon-female-sign:before {
  content: "\e906";
}

.icon-heart-fill:before {
  content: "\e908";
}

.icon-instagram:before {
  content: "\e909";
}

.icon-left-arrow:before {
  content: "\e90a";
}

.icon-linkedin:before {
  content: "\e90b";
}

.icon-male-sign:before {
  content: "\e90c";
}

.icon-pencil:before {
  content: "\e90d";
}

.icon-right-arrow:before {
  content: "\e90f";
}

.icon-right-arrow-alt:before {
  content: "\e910";
}

.icon-ring:before {
  content: "\e911";
}

.icon-share:before {
  content: "\e912";
}

.icon-twitter:before {
  content: "\e914";
}

.icon-user:before {
  content: "\e915";
}

.icon-user-circle:before {
  content: "\e916";
}

.icon-whatsapp:before {
  content: "\e917";
}
