.submit-grid-container {
  transition: all 0.4s ease-in-out;

  &:hover,
  &.active {
    cursor: pointer;
    background-color: #e4e4e4ed;
  }
  .status {
    color: #7c7c7c;
    font-size: 1rem;

    &.submitted {
      color: #327cf6;
    }

    &.active {
      color: #e8ad0d;
    }

    &.complete {
      color: #5cc889;
    }
  }
}
