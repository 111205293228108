.custom-modal.editProfile {
  .edit-work,
  .edit-education {
    input:not([type="checkbox"]) {
      // padding: 0;
      // border: none;
      &:focus-visible {
        // border: none;
        // border-radius: 0;
        // outline: none;
      }
    }
    .add-new-item-action-btns {
      button {
        color: #fff;
        background-color: #000;
      }
    }
  }
}
// Select 2
.select2-dropdown {
  z-index: 99999 !important;
  font-family: "Helvetica Neue MediumCond";
}
.select {
  select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    position: relative;

    &:disabled {
      background-color: #edf5fa;
      color: #788995;
      border-color: #dee6ec;
    }
  }

  select::-ms-expand {
    display: none;
  }
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 33px;
  border: 1px solid #ddd;
  padding: 0px 5px !important;
  border-radius: 5px;
}

.select2 {
  outline: none;
  min-width: 100% !important;
  max-width: 100% !important;
  font-family: "Helvetica Neue MediumCond";

  .select2-selection__placeholder {
    color: #007598 !important;
  }

  .select2-selection__rendered {
    text-transform: uppercase;
    color: #000 !important;
    padding-left: 0 !important;
  }

  .select2-selection {
    min-height: calc(2em + 0.81rem);
    outline: none;
    border: none !important;
    border-radius: 5px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: auto;

    &:focus {
      outline: none;
    }
  }

  #brand-nationality + & {
    .select2-selection {
      min-height: calc(2em + 0.625rem);
      margin-top: 0.25rem;
    }
  }

  .select2-selection__arrow {
    b {
      border-style: none !important;

      &::after {
        position: absolute;
        right: 5px !important;
        left: auto;
        top: 0.35rem;
        content: "\e910" !important;
        font-family: "icomoon";
        font-size: 0.4rem;
        transition: 0.3s ease;
        color: #007598;
      }
    }
  }

  &.select2-container--open {
    .select2-selection__arrow {
      b {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.select2-dropdown {
  border: none;
  box-shadow: 0px 5px 2rem rgba(44, 44, 44, 0.09);
  padding: 20px 15px;
  margin-top: 5px;
  border-radius: 0.286rem !important;

  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 25px;
    top: -2px;
    -webkit-transform: rotate(45deg);
    transform: rotate(29deg) skew(0deg, 30deg);
    border-radius: 4px;
    background: #ffffff;
    right: 10px;
    z-index: -1;
  }

  &.select2-dropdown--above {
    margin-top: -5px;

    &::before {
      top: inherit;
      bottom: -5px;
    }
  }
}

////////////////////////
//  results__option
////////////////////////
.select2-results__options {
  .select2-results__option {
    border-radius: 0.286rem;
    line-height: 2;
    border-top: 1px solid #e2e2e2;
    border-radius: 0;
    padding-right: 15%;
    font-size: 14px;
    font-family: "Helvetica Neue ThinCond";
    font-weight: 600;
    letter-spacing: 1px;

    &:first-child {
      border-top: 0;
      background: #f9fafa;
    }
  }
}

////////////////////////
//  search__field
////////////////////////

.select2-search--dropdown {
  padding: 0 !important;
  position: relative;

  &::after {
    position: absolute;
    font-family: "icomoon";
    right: 10px;
    top: 0;
    color: #007598;
    line-height: 40px;
  }

  .select2-search--inline {
    width: 100%;
  }

  .select2-search__field {
    height: calc(2.8578rem + 2px);
    border-radius: 0.286rem;
    border-color: #d8d8d8;
    padding: 0.857rem;
    padding-right: 1.5rem;
    font-size: 14px;

    &:focus {
      outline: none;
    }
  }

  input[aria-controls="select2-rejection_reason-results"] {
    display: none;
  }
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: none;
  position: relative;
  color: #000;

  &::before {
    position: absolute;
    content: "";
    border-radius: 50px;
    right: 0;
    width: 3px;
    height: 100%;
    top: 0;
    bottom: 0;
    background: #000;
  }
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background: #f9fafa;
  position: relative;
  color: #000;
  border-radius: 0.286rem;

  &,
  & + li {
    border-color: transparent;
  }

  &::after {
    position: absolute;
    content: "\e923";
    font-family: "icomoon";
    right: 10px;
    top: 5px;
    color: #000;
    font-weight: bold;
    font-size: 15px;
  }
}

// Multi select Item css

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  padding: 0;
  padding-right: 1rem;
  background: #f9fafa;
  color: #000;
  border: none;
  font-size: 14px;
  line-height: 1.2;

  span {
    margin-right: 0.4rem !important;
    color: #000;
    border-right: 1px solid rgba(160, 160, 160, 0.23);
    padding: 0 0.5rem;
    font-weight: normal;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 22px;
    padding-top: 2px;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }
}

.select2-container--default .select2-search--inline {
  width: 100%;

  .select2-search__field {
    width: 100% !important;
    margin-top: 10px;
    height: inherit;
    -webkit-text-fill-color: #000;
  }
}

.select2-container--default .select2-selection--multiple:before {
  position: absolute;
  right: 10px;
  top: 1.18rem;
  content: "\e911";
  font-family: "icomoon";
  font-size: 0.4rem;
  transition: 0.3s ease;
  color: #007598;
}

.select2-container--open .select2-selection--multiple:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.select2-results {
  position: relative;
  overflow: hidden;
  right: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  right: 0px;
  position: relative;
  padding-right: 7px;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #edf5fa;
  color: #788995;
  border-color: #dee6ec !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 17px;
  width: auto !important;
}

// datepicker
.date-control {
  position: relative;
  &::after {
    position: absolute;
    right: 25px;
    top: 40%;
    transform: translateY(-50%);
    content: "\e924";
    font-family: "icomoon";
    transition: 0.3s ease;
    color: #000;
    z-index: 1;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    input {
      cursor: not-allowed;
      background-color: transparent;
    }
  }
  input {
    position: relative;
    z-index: 2;
    cursor: pointer;
    background: transparent;
    &:focus {
      background-color: transparent;
    }
  }
}
.form-check {
  display: inline !important;
  position: relative;
  // margin-left: 1.5em;
  .form-check-input {
    position: absolute;
    left: 0;
    margin-left: 0 !important;
    &:focus {
      box-shadow: none;
      border-color: #000;
    }
    &:checked {
      background-color: #000000;
      border-color: #000000;
    }
  }
  label {
    font-family: "Helvetica Neue MediumCond";
  }
}
.datepicker {
  table {
    width: 100%;
    position: relative;
    border-collapse: separate;
    padding-top: 2.5rem;

    thead {
      position: relative;
    }

    th {
      text-align: center;
      color: #ddd;
      font-size: 1rem;
      padding-bottom: 1rem;
      padding-top: 1rem;

      &.dow {
        border-bottom: 1px solid #dde3e8;
        border-radius: 0;
      }
    }

    td {
      padding: 0.2rem 0;
      font-size: 14px;
      color: #868fa5;
      line-height: 40px;

      &.day {
        background: transparent !important;
        width: 31px;
        height: 44px;
        vertical-align: baseline;

        .date-day {
          width: 22px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          border-radius: 50%;
          display: block;
          margin: 0 auto;
          float: none;
          cursor: pointer;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          transition: all 0.3s;
          position: relative;
        }

        &:hover {
          .date-day {
            background: #000;
            color: #fff;
            border-radius: 50%;
          }
        }

        &.active {
          pointer-events: none;
          background: #007598 !important;
          color: #fff;
          .date-day {
            border: 1px solid #000;
            width: 31px;
            height: 44px;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

// PopUp Date Picker
.datepicker.datepicker-dropdown {
  padding: 2rem 0.6rem 0.3rem;
  text-transform: uppercase;
  max-width: 340px;
  width: 100%;
  text-align: center;
  margin-left: 20px;
  font-family: "Helvetica Neue MediumCond";

  table {
    width: 100%;
    position: relative;
    border-collapse: separate;

    thead {
      position: relative;

      .datepicker-switch {
        position: absolute;
        right: 0;
        top: -35px;
        color: #23242e;
        height: 0;
        padding: 0;
        font-size: 1.166rem;
        width: 100%;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
        &:hover {
          background: inherit;
          cursor: inherit;
        }
      }

      .dateHejriDay {
        position: relative;
        top: -5px;
      }

      .prev,
      .next {
        width: 1.6rem;
        height: 1.6rem;
        overflow: hidden;
        border-radius: 50%;
        display: inline-block;
        padding: 0;
        color: #000;
        position: absolute;
        text-indent: -999px;
        top: -35px;
        background: none !important;
        box-shadow: none !important;

        &:hover {
          color: #000;
        }

        &::after {
          position: absolute;
          right: 0;
          width: 1.429rem;
          height: 1.429rem;
          text-indent: 0;
          font-family: "icomoon";
          font-size: 12px;
        }

        &.disabled {
          display: none;
        }
      }

      .prev {
        left: 0;
        right: inherit;
        transform: rotate(180deg);
        margin-top: -5px;

        &::after {
          content: "\e90f";
          font-family: "icomoon";
        }
      }

      .next {
        right: 0;

        &::after {
          content: "\e90f";
          font-family: "icomoon";
        }
      }
    }

    th {
      padding-bottom: 10px;
      padding-top: 2.188rem;
      color: #007598;
      font-size: 14px;
    }

    tr {
      td {
        padding: 2px 0;
        border-radius: 50%;
        font-size: 14px;
        color: #000;
        border: 1px solid transparent;

        &.day {
          &:hover {
            border: 1px solid #000;
          }
          &.disabled {
            color: #ccd1de !important;
            cursor: not-allowed !important;
          }
        }
      }
    }
  }
}
