.create-list-container {
  transition: all 0.4s ease-in-out;

  .title {
    color: #000;
    font-size: 0.9rem;
  }

  .date {
    color: #7c7c7c;
    font-size: 0.9rem;
  }
}
