.section-title {
  font-size: 1.1rem;
}
.item-container {
  font-size: 14px;
}
.edit-education-wrap,
.edit-work-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  overflow: auto;
}
.work-history {
  overflow: auto;
  max-height: 300px;
  flex: 1;
}
