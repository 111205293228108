.lists-container {
    // padding: 4.5rem 3rem;

    .list-item {
        transition: all 0.4s ease-in-out;

        &:hover {
            cursor: pointer;
            background-color: #E4E4E4ed;
        }

        .list-date {
            color: #7C7C7C;
        }
    }

    ul {
        list-style: none;
    }
}