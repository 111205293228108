.candidate-modal {
  position: fixed;
  top: 0;
  right: -100%;
  background-color: #ccc;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  overflow: hidden;
  background-color: #e4e4e4;
  transition: all 0.4s ease-in-out;

  &.is-open {
    right: 0;
  }

  .close-modal {
    position: absolute;
    top: 30px;
    left: 40px;
    color: #fff;
    // font-size: 1.5rem;
    z-index: 5;

    i {
      color: inherit;
    }
  }

  .modal-content-container {
    .actions-btns {
      .delete-candadite-btn {
        color: #cc0000;
        font-size: 1.5rem;
      }
    }

    .candidate-shape {
      height: 100vh;

      i {
        color: #fff;
        cursor: pointer;
        font-size: 1.5rem;
      }

      .images-container {
        .main-side {
          position: relative;
          margin-right: 25px;
        }

        .thumbnails-side {
          min-width: 65px;
          overflow: auto;
          padding: 1rem 0;

          img {
            cursor: pointer;
            height: 100px;
            width: 65px;
            object-fit: cover;
          }

          .video-item {
            position: relative;

            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #cc0000;
            }
          }
        }

        .display-item {
          > img {
            // height: 720px;
            height: 100%;
            max-width: 550px;
            max-height: 960px;
            object-fit: cover;
          }
        }
      }
    }

    .candidate-info-container {
      height: 100vh;
      overflow: auto;
      background-color: #fff;
    }
  }
}
