.control-bar-container {
  padding: 12px 0;
  background: #f0f0f0;
  position: fixed;
  width: 100%;
  left: 0;
  top: 74px;
  z-index: 999;

  .breadcrumb-container {
    min-width: 340px;
  }

  .store-search-name {
    width: 100%;
  }

  .breadcrumb {
    i {
      color: #7c7c7c;
      font-size: 0.8rem;
      padding: 0;
    }

    .custom-w {
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .filters-actions {
    .popover__trigger {
      font-size: 1.2rem;
    }

    .body-filter {
      min-width: 35px;

      > span {
        display: none;
        cursor: pointer;
        font-size: 0.9rem;

        &.active {
          display: block;
        }
      }
    }

    input[type="range"] {
      margin-right: 100px;
    }
  }

  .add-list-container {
    // @media (min-width:1440px) {
    padding-left: 40px !important;
    // }

    .add-list-icon {
      padding: 4px;
      background: #fff;
      // font-size: 14px;
      margin-right: 5px;
      border-radius: 100px;
      font-size: 0.8rem;
    }

    .add-list-title {
      cursor: pointer;
    }
  }

  .side-bar-control {
    > * {
      transition: all 0.4s ease-in-out;
    }

    .open-tags {
      margin-right: 15px;
    }

    .active {
      font-family: "Helvetica Neue MediumCond";
    }
  }

  .popover {
    display: inline-block;
    position: relative;
    background: transparent;
    border: none;
    max-width: initial;
    // font-size: 17px;

    .popover__menu {
      border-radius: 4px;
      background: #fff;
      list-style-type: none;
      margin: 8px 0;
      min-width: 100%;
      width: 190px;
      padding: 1rem 0;
      position: absolute;
      top: 100%;
      left: 0;
      box-shadow: 0 2px 3px 0 rgba(63, 63, 68, 0.2);

      transition-property: transform, opacity;
      transition-duration: 200ms;
      transition-timing-function: ease;

      transform: scale(0.9);
      transform-origin: top left;
      display: none;
      opacity: 0;
    }

    .popover__menu-item {
      white-space: nowrap;
      padding: 0.4rem 1rem;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
      font-size: 0.8rem;

      &:hover {
        background-color: #f1f1f1;
      }
      &.active {
        color: #fff;
        background-color: #000000;
        i.active {
          opacity: 1;
        }
      }
      i {
        opacity: 0;
        color: #fff;
      }
    }

    &.popover--active .popover__menu {
      transform: scale(1);
      display: block;
      opacity: 1;
    }
  }

  .list-tab {
    // font-weight: 600;
    cursor: pointer;

    &.active {
      span {
        font-family: "Helvetica Neue MediumCond";
      }
    }

    .list-number {
      color: #7c7c7c;
    }
  }
}
