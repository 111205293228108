.status-popover {
  display: inline-block;
  position: relative;
  background: transparent;
  border: none;
  max-width: initial;
  // font-size: 17px;

  .popover__menu {
    border-radius: 4px;
    background: #fff;
    list-style-type: none;
    margin: 8px 0;
    min-width: 100%;
    padding: 0;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 1px 3px 2px rgba(63, 63, 68, 0.2);

    transition-property: transform, opacity;
    transition-duration: 200ms;
    transition-timing-function: ease;

    transform: scale(0.9);
    transform-origin: top left;
    display: none;
    opacity: 0;
  }

  .popover__menu-item {
    white-space: nowrap;
    padding: 0.5rem 1rem;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    font-size: 0.9rem;
    border-bottom: 1px solid #ddd;
    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }

  &.popover--active .popover__menu {
    transform: scale(1);
    display: block;
    opacity: 1;
  }

  #datepicker-container {
    .datepicker.datepicker-dropdown {
      left: auto !important;
      right: 0 !important;
      top: 30px !important;
      min-width: 320px;
    }
  }
}
