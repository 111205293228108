// .message-container {
//     position: absolute;
//     top: 0;
//     right: 0;
// }
.toast {
  width: 400px;
  position: relative;
  overflow: hidden;
  list-style: none;
  border-radius: 4px;
  padding: 16px 17px;
  margin-bottom: 10px;
  background: var(--light);
  justify-content: space-between;
  animation: show_toast 0.3s ease forwards;
}
