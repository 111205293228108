.edit-contact {
  .country-code-container {
    position: relative;
    .country-code {
      display: flex;
      align-items: center;
      background: #ddd;
      padding: 2px 5px;
    }
    .country-number {
      padding: 5px 7px !important;
      padding-right: 20px !important;
    }
    .call-icon,
    .whatsapp-icon {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }
    .call-icon {
      i {
        color: #7c7c7c;
      }
    }
    .whatsapp-icon {
      i {
        color: #25d366;
      }
    }
  }
}
