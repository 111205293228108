.delete-candidate-modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: #0000008a;
    z-index: 999999;
    display: none;

    &.is-open {
        display: block;
    }

    .modal-content-container {
        padding: 3rem 1rem;
        min-width: 500px;
        // min-height: 400px;
        background: #fff;
        border-radius: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .close-wrapper {
            // padding: 25px;
            width: 70px;
            height: 70px;
            display: block;
            margin: auto;
            border: 3px solid #CC0000;
            border-radius: 50%;
            line-height: 70px;

            i {
                color: #CC0000;
                font-size: 1.5rem;
            }
        }

        .delete-title {
            color: #CC0000;
            font-family: 'Helvetica Neue BlackCond';
        }

        .text-msg {
            font-size: 1.2rem;

            span {
                color: #CC0000;
            }
        }

        .action-btns {
            .cancel-btn {
                background-color: #E4E4E4;
            }

            .delete-btn {
                background: #CC0000;
                color: #fff;
                border: none;
            }
        }
    }

}