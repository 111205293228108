.custom-modal {
  &.editProfile {
    width: 40%;
    background: #fff;
    top: 170px;
    height: calc(100vh - 170px);
    transition: all 0.6s ease-in-out;
    overflow: visible;

    input:not([type="checkbox"]) {
      width: 100% !important;
      padding: 5px;
      color: #333;
      font-family: "Helvetica Neue MediumCond";
      border-radius: 5px;
      border: 1px solid #ddd;

      // width: calc(100% - 30px);
      &:focus-visible {
        border: 1px solid #ddd;
        border-radius: 0;
        outline: none;
      }
      &::placeholder {
        font-size: 0.8rem;
        color: #a6a6a6;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder {
        font-size: 0.8rem;
        /* Edge 12-18 */
        color: #a6a6a6;
      }
    }
    .edit-profile-tabs {
      background: transparent;
      position: absolute;
      right: 15px;
      top: -33px;

      .active {
        font-family: "Helvetica Neue MediumCond";
      }
    }
    .edit-profile-action-btns {
      padding-top: 15px;
      border-top: 1px solid #ddd;
    }
    .border-top {
      border-top: 1px solid #ddd;
    }
  }
}
#portal {
  .edit-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000007d;
    z-index: -1;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    &.in {
      z-index: 9999;
      opacity: 1;
    }
  }
}
