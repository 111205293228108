.wrapper {
    position: relative;

    .range-container {
        position: relative;
        width: 100%;
        height: 50px;
    }

    input[type="range"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        outline: none;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        background-color: transparent;
        pointer-events: none;
    }

    .slider-track {
        width: 100%;
        height: 2px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        z-index: 9;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        height: 2px;
        background-color: #ccc;
    }

    input[type="range"]::-moz-range-track {
        -moz-appearance: none;
        height: 5px;
    }

    input[type="range"]::-ms-track {
        appearance: none;
        height: 5px;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 14px;
        width: 14px;
        background-color: #7C7C7C;
        cursor: pointer;
        margin-top: -5px;
        pointer-events: auto;
        border-radius: 50%;
        position: relative;
        z-index: 9;
    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 14px;
        width: 14px;
        background-color: #7C7C7C;
        cursor: pointer;
        margin-top: -5px;
        pointer-events: auto;
        border-radius: 50%;
        position: relative;
        z-index: 9;
    }

    input[type="range"]::-ms-thumb {
        appearance: none;
        height: 1.7em;
        width: 1.7em;
        cursor: pointer;
        border-radius: 50%;
        background-color: #7C7C7C;
        pointer-events: auto;
    }

    input[type="range"]:active::-webkit-slider-thumb {
        background-color: #ffffff;
        border: 3px solid #7C7C7C;
    }

    .values {
        background-color: #3264fe;
        width: 32%;
        position: relative;
        margin: auto;
        padding: 10px 0;
        border-radius: 5px;
        text-align: center;
        font-weight: 500;
        font-size: 25px;
        color: #ffffff;
        display: none;
    }

    .values:before {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        border-top: 15px solid #3264fe;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        margin: auto;
        bottom: -14px;
        left: 0;
        right: 0;
    }

    .percent-num {
        font-size: 14px;
        position: absolute;
        top: -5px;
    }
}