.item-grid-container {
  transition: all 0.4s ease-in-out;

  &:hover,
  &.active {
    cursor: pointer;
    background-color: #e4e4e4ed;
  }

  .title {
    color: #000;
    font-size: 0.9rem;
  }

  .date {
    color: #7c7c7c;
    font-size: 0.9rem;
  }
}
