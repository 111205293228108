.candidate-profile-data {
  &.is-open {
    right: 0;
  }

  .modal-content-container {
    .actions-btns {
      .delete-candadite-btn {
        color: #cc0000;
        font-size: 1.5rem;
      }
    }

    .candidate-shape {
      height: calc(100vh - 170px);

      i {
        color: #fff;
        cursor: pointer;
        font-size: 1.5rem;
      }

      .images-container {
        &:hover .main-side + .thumbnails-side {
          opacity: 1;
        }
        .main-side {
          margin-right: 25px;
        }

        .thumbnails-side {
          min-width: 65px;
          overflow: auto;
          padding: 1rem 0;
          opacity: 0;
          transition: all 0.4s ease-in-out;

          img {
            cursor: pointer;
            height: 100px;
            width: 65px;
            object-fit: cover;
          }

          .video-item {
            position: relative;

            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #cc0000;
            }
          }
        }

        .display-item {
          > img {
            // height: 720px;
            height: 90%;
            width: 100%;
            max-width: 550px;
            max-height: 960px;
            object-fit: cover;
          }
        }
      }
    }

    .candidate-info-container {
      height: calc(100vh - 170px);
      overflow: auto;
      background-color: #fff;
    }
  }
}
