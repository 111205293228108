.edit-socials {
  .social-item {
    i {
      width: 17px;
      color: #7c7c7c;
      font-size: 1.3rem;
      margin-right: 20px;
    }
    input {
      width: calc(100% - 30px);
    }
    .input-wrapper {
      width: calc(100% - 37px);
    }
    .validation-message {
      margin-left: 37px;
      font-size: 14px;
      font-family: "Helvetica Neue MediumCond";
      color: #cc0000;
    }
  }
}
.edit-profile-action-btns {
  &.edit-socials {
    margin-top: 85px;
  }
}
