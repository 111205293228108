@font-face {
    font-family: 'Wilden';
    src: url('../../fonts/wilden/Wilden.eot');
    src: url('../../fonts/wilden/Wilden.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/wilden/Wilden.woff2') format('woff2'),
        url('../../fonts/wilden/Wilden.woff') format('woff'),
        url('../../fonts/wilden/Wilden.ttf') format('truetype'),
        url('../../fonts/wilden/Wilden.svg#Wilden') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue ThinCond';
    src: url('../../fonts/helvetica/HelveticaNeue-ThinCond.eot');
    src: url('../../fonts/helvetica/HelveticaNeue-ThinCond.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/helvetica/HelveticaNeue-ThinCond.woff2') format('woff2'),
        url('../../fonts/helvetica/HelveticaNeue-ThinCond.woff') format('woff'),
        url('../../fonts/helvetica/HelveticaNeue-ThinCond.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue MediumCond';
    src: url('../../fonts/helvetica/HelveticaNeue-MediumCond.eot');
    src: url('../../fonts/helvetica/HelveticaNeue-MediumCond.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/helvetica/HelveticaNeue-MediumCond.woff2') format('woff2'),
        url('../../fonts/helvetica/HelveticaNeue-MediumCond.woff') format('woff'),
        url('../../fonts/helvetica/HelveticaNeue-MediumCond.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue BlackCond';
    src: url('../../fonts/helvetica/HelveticaNeue-BlackCond.eot');
    src: url('../../fonts/helvetica/HelveticaNeue-BlackCond.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/helvetica/HelveticaNeue-BlackCond.woff2') format('woff2'),
        url('../../fonts/helvetica/HelveticaNeue-BlackCond.woff') format('woff'),
        url('../../fonts/helvetica/HelveticaNeue-BlackCond.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}