.input-grid-container {
  position: relative;
  transition: all 0.4s ease-in-out;
  &.active {
    background-color: #e4e4e4ed;
    i.edit {
      display: block;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #e4e4e4ed;
  }
  i.edit {
    position: absolute;
    left: -24px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }
  .title {
    color: #000;
    font-size: 0.9rem;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .date {
    color: #7c7c7c;
    font-size: 0.9rem;
  }
}
