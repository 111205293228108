.edit-documents {
  .document-item {
    font-family: "Helvetica Neue MediumCond";
    & + .document-item {
      margin: 20px 0;
    }
    & + .edit-profile-action-btns {
      margin-top: 1.5rem;
    }

    .documnet-upload-file {
      position: relative;
      overflow: hidden;
      display: inline-block;
      .upload-btn {
        cursor: pointer;
        font-weight: bold;
      }
      input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        height: 20px;
        display: none;
      }
    }
  }
  .document-info {
    font-family: "Helvetica Neue MediumCond";
  }
}
